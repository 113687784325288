/* You can add global styles to this file, and also import other style files */
/* @import '~@angular/material/prebuilt-themes/deeppurple-amber.css'; */
/* @import '~@corteva-research/ngx-components-core/assets/fonts/fonts.css'; */

@import url("https://fonts.googleapis.com/icon?family=Material+Icons");

@import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
@import "../node_modules/primeicons/primeicons.css";
@import "../node_modules/primeng/resources/themes/nova/theme.css";
@import "../node_modules/primeflex/primeflex.min.css";
@import "../node_modules/primeng/resources/primeng.min.css";

@import "../node_modules/@corteva-research/ngx-components-core/assets/styles/research-web-components.scss";
@import "../node_modules/@corteva-research/ngx-components-core/assets/fonts/fonts.scss";


@import "../node_modules/ag-grid-community/src/styles/ag-grid.scss";
@import "../node_modules/ag-grid-community/src/styles/ag-theme-alpine/sass/ag-theme-alpine-mixin.scss";
@import '../node_modules/@angular/material/prebuilt-themes/deeppurple-amber.css';
.ag-theme-alpine {
    @include ag-theme-alpine();
}


[hidden] { display: none !important;}

.theme-light {
    --theme-font-size-base: 14px !important;
}

.theme-dark {
    --theme-font-size-base: 14px !important;
}

/* override css to fix submenu full width issue*/
.nav-group-content-wrapper {  
    left: unset !important;
    right: unset !important;
    height: auto !important;
}

div.nav-group-content-wrapper {
    background-color:#e6e8ea !important;
    border-left: 2px solid darkgray !important;
    border-right: 2px solid darkgray !important;
    border-bottom: 2px solid darkgray !important;
}

/*
.resShadow {
     box-shadow: 2px 0px 10px;
     border-radius: 5px !important;    
    border: 1px solid  rgba(0, 0, 0, 0.15);
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.15);
    background-color: white;
    font-size: 12px;

 }
 */

.resShadow-1 {
    // box-shadow: 2px 0px 10px;
   //  border-radius: 5px !important;    
    border-top: 1px solid  rgba(0, 0, 0, 0.15);
   // border-bottom: 1px solid  rgba(0, 0, 0, 0.15);
  //  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.15);
    background-color: white;
    font-size: 12px;

}

// To highlight the data view row on hover
.hover .resShadow-1:hover {
    color: #1a2326;
    text-decoration: none;
    border:1px solid #2b85e7;
    border-radius: 4px;
    box-shadow: 4px 8px 8px rgba(1,67,163,.24), 0 0 8px rgba(1,67,163,.12), 0 6px 18px rgba(43,133,231,.12);
    //transform: translateY(-2px);  
  }


.nav-group-compact.nav-group-wrapper .nav-group-content-wrapper {
    min-width: 0 !important;
  }
  

.titleColor{
    color: #0072CE;
}

.bottom{
    margin-top:70% !important;
}

.padding30px{
    padding: 30px !important;

}
.searchInputBox{    
    border-radius: 6px !important;
    width:85%;
    line-height: 2.05em;
}

.searchButton{
    margin-left: -79px !important;        
}

.pi-search{    
    position: absolute;
    margin-left: -40px;
    padding-top: 3px;
    padding-bottom: 10px;
    padding-right: 10px;
    cursor: pointer;
    color:orangered;
    font-size: 2.5em !important;    
}

.trash
{
font-size: 1.6em !important;
vertical-align: bottom;
padding-left: 8px;
color: orangered;
cursor: pointer;
}

// body .p-dataview .p-dataview-header {
//     background-color:unset !important;
//     color: #333333;
    
//     font-weight: 700;
//     border-bottom: 0 none;
//     padding: 0.571em 0em 1em 0em !important;
// }

// body .p-dataview .p-dataview-content {
//     border: unset !important;
//     color: #333333;
//     padding: unset !important;  
// }
 
.p-dataview .p-dataview-header {
    font-weight: 600 !important;
}

body .p-dataview {
    border: unset !important;
    box-shadow: unset;
}

body .p-dataview .p-dataview-header .clsDataViewHeaderVisiblePart{
    height: 50px;
}
// body .p-paginator {
//     background-color: #f4f4f4;
//     border: unset !important;
//     padding: unset !important;
//     border-radius: 5px !important;
// }

.envName{
    font-size: 50% !important;
    background: #dc3545 !important;
    color: white !important;
    vertical-align: top !important;
    padding-left: 5px;
    padding-right: 5px;
}

.unauthorized .p-dialog .p-dialog-header {
    background-color: maroon;
    color: white;
    width: 100%;
    box-shadow: 10px 10px 5px #aaaaaa;
}

.unauthorized .p-dialog .p-dialog-content{
    font-size: 13px;
    color:black;
    padding-left: 51px;
    padding-top: 20px;
    box-shadow: 10px 10px 5px #aaaaaa;
    text-align: left;
}

.moreSynonyms{
    margin-top:35px;
}

 body .showAll{
    background-color: white !important;
    color:blue !important;
    border: none !important;
    float: left;
    margin-top:-4px;
}

 .showAll .p-button-text{
    padding-left: 0px !important;
}

 .showAll .p-button-icon-right{
    top: 54%;
    font-size: 1.1em
}

 .showAll .p-button:enabled:hover{
    background-color: white !important;
    color:black !important;
    
}

body .hideAll{
    background-color: white !important;
    color:blue !important;
    border: none !important;
    float: left;
    margin-top:-4px;
}

 .hideAll .p-button-icon-right{
    top: 54%;
    font-size: 1.1em
}

 .hideAll .p-button:enabled:hover{
    background-color: white !important;
    color:black !important;
    
}

 .firstSynonym{
    float:left; 
    padding-left:5px;
}

// To highlight the data view row on hover
.hover .resShadow:hover {
    color: #1a2326;
    text-decoration: none;
    border:1px solid #2b85e7;
    border-radius: 4px;
    box-shadow: 4px 8px 8px rgba(1,67,163,.24), 0 0 8px rgba(1,67,163,.12), 0 6px 18px rgba(43,133,231,.12);
    //transform: translateY(-2px);  
  }

  body .p-dataview .p-dataview-footer{
      border:unset !important;
  }

 body  .p-button{
    background-color: #007ad9 !important;
    font-size: 12px !important;
    height: 30px;
}

.mat-step-header .mat-step-icon-selected {
    background-color: #2b85e7; 
 }

 
.check-green
{
    color: green;
    font-size: 20px !important;
    vertical-align: middle;
}

.cross-red
{
    color:#e91224bf !important;
    font-size: 20px !important;
    vertical-align: middle;
}

.vertical-align-middle
{
    line-height: 70px;
}

body .p-fileupload-content .p-button {

    cursor:pointer;
    background-color: #ef4d5b !important;
    
}

.messages .p-messages-icon
{
    display: none !important;
}


.uploadFileValidation {
	font-family: verdana,arial,sans-serif;
    color:#333333;
    width: 100%!important;
    margin-top: -15px;
}
.uploadFileValidation th {
    font-size:12px; 
    font-weight: normal;
	padding: 8px;	
    border-bottom: 1px solid #ece5e5fb;
    line-height: 10px;
    
   
}
.uploadFileValidation td {
    font-size:11px;
    font-weight:normal;
	padding: 8px;
    border-bottom: 1px solid #dcc0c075;
    line-height: 10px;
}

.text-right{
    text-align: right;
}

.font-bold
{
    font-weight: bold !important;
}

.file-upload-summary {
	font-size: 12px !important;
    font-weight: normal;
}

.p-messages .p-messages-summary {
    font-weight: bold; 
    margin-left: .0em !important;
}

.p-messages.p-messages-error {
    background-color: #f5d9db !important;
    border: 0 none;
    color: #212121;
}

.new-file-text
{
    font-size: 8px; 
    position: absolute;  
    margin-left: 5px;
}

 .checkboxes{
    padding: 0px 15px 15px 15px;
}


.fontSize12px{
    font-size: 12px !important;
}

.p-dialog .p-dialog-header {
    background-color:#007ad9;
    color: white;
    height: 50px;
}

.dialog .p-dialog .p-dialog-header {
    background-color:#007ad9;
    color: white;
}
body .pi {
    font-size: 1.0rem !important;
}
.dialog .p-dialog-header-close {
    display:none;
  }

  .p-dialog .p-dialog-header .p-dialog-header-icon {
      color: white !important;
      font-size: 1.0rem !important;
  }

.dialog .p-dialog .p-dialog-footer {
    background-color:#dbdbdb
}

.containerLabel .p-dropdown-label{
    width:420px;
}

.containerLabel .p-dropdown-panel .p-dropdown-item {
    font-size: 12px !important;
}

.green-button
{
    cursor: default !important;
    color: darkgreen !important;
    background-color: #34a83536 !important;
    border: 0px solid #34a83536 !important; 
    font-weight: bold !important;
}

.red-button
{
    cursor: default !important;
    color: #e00d20 !important;
    background-color: #e912242b !important;
    border: 0px solid #e912242b !important;
    font-weight: bold !important;
}

.remove-button
{
    cursor:pointer !important;;
    background-color: #e91224bf !important;;
}

.dialog .p-dialog{
    box-shadow: rgba(8, 8, 8, 0.72) 0px 3px 3px, rgba(0, 0, 0, 0.71) 0px 0px 8px, rgba(4, 4, 4, 0.38) 0px 6px 18px;
}

.invalid-feedback{
    display:block;
}

.uomMapMultiSelect .p-multiselect-label-container{
    width:200px !important;
    font-size: 12px !important;
    height: 30px !important;
}


.messages .p-messages ul {
    display:block !important;
    font-size: 10px;
    width: 100%!important;
}

.address .p-g-12 {
    padding-top: 2px !important;
    padding-bottom: 2px !important;
}

.mat-step-label-selected {
    font-size: 14px;
    font-weight: 800;
    color: #2b85e7 !important;
}

.colors{
    background-color: #dbdbdb
}

 .formStyle .is-invalid{
    background-image: unset !important;
}

.errorColor{
    color: #dc3545
}

.dropdownCSS .form-control{
    padding: unset !important;
    //height: calc(1.2em + .70rem + 2px);
    height: 0% !important;
    border: 1px solid #a6a6a6 !important;
}

.dropdownCSS .p-dropdown{
    border:unset !important;
}

.dropdownCSS .is-invalid{
    border-color: #dc3545 !important;
}
.dropdownCSS .is-invalid .p-dropdown-label{
    color: #dc3545 !important;
}

.dropdownCSS .is-invalid .p-dropdown-trigger{
    color: #dc3545 !important; 
}

.dropdownCSS .p-dropdown-label{
    padding-top: 5px !important;
}

body .p-carousel-items-container .p-carousel-item {
    width: 100%;
    box-sizing: border-box;
    flex: 0 0 25% !important;
    min-height: 287px;
}


:host ::ng-deep .p-dataview .p-paginator-top {
    border: none !important;
}

:host ::ng-deep .p-dataview .p-paginator-bottom {
    border: none !important;
}
.form-panel-subheader {
    font-size: 15px;
    font-weight: bold;
}

.form-label-header {
    font-size: 14px;
    font-weight: bold;
  }

.p-dropdown-items-wrapper {width: 240px !important;}
.p-dropdown-label {width: 139px !important; line-height: 1rem; }
.p-dropdown-panel {
    line-height: 1rem;
}
  .p-selectbutton.p-buttonset .p-button {
      background-color: #f4f4f4 !important;
      color: black !important;
  }

  
  .p-selectbutton.p-buttonset .p-button.p-highlight {
    background-color: #007ad9 !important;
    color: white !important;
}


.p-dataview .p-paginator-first {
    border: none !important;
}

.p-dataview .p-paginator-first {
    border: none !important;
}


.p-panel-header { height: 46px !important; padding: unset !important; }
.p-panel-footer { height: 46px !important; padding: unset !important; }

.form-panel-subheader {
    margin-left:5px;
}

.p-datatable .p-datatable-wrapper .p-datatable-tbody > tr:nth-child(even){
    background-color: #f3f3f3;
  }

.p-dropdown-materialplanner {
    .p-dropdown-label {
        font-weight: 700;
        width: 194px !important;
    }
}

.p-toast-top-right {
    top: 80px !important;
}

.p-component {
    font-size: 0.875rem !important;
}

.p-datepicker {
    min-width: 350px !important;
    padding: unset !important;
}
.p-datepicker table  {
    margin: unset !important;
}
.p-datepicker table td>span {
    width: unset !important;
    height: unset !important;
    font-size: 12px !important;
}